
import React, { createContext, useState, useEffect, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const ThemeContext = createContext();

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: { main: '#1068AE' },  // Main blue from logo
          secondary: { main: '#24BCE2' }, // Light blue accent
          background: {
            default: '#ffffff',
            paper: '#f5f7fa',
          },
          text: {
            primary: 'hsl(220, 20%, 25%)',
            secondary: 'hsl(220, 20%, 35%)',
          },
        }
      : {
          primary: { main: '#24BCE2' },  // Using light blue as primary in dark mode
          secondary: { main: '#1068AE' }, // Main blue as secondary
          background: {
            default: '#16242D',    // Dark background from color palette
            paper: '#1e2f3a',      // Slightly lighter shade for paper
          },
          text: {
            primary: 'hsl(0, 0%, 100%)',
            secondary: 'hsl(220, 20%, 65%)',
          },
        }),
  },
});

export const ThemeProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(localStorage.getItem('themeMode') || (prefersDarkMode ? 'dark' : 'light'));

  useEffect(() => {
    localStorage.setItem('themeMode', mode);
  }, [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ThemeContext.Provider value={{ theme, colorMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

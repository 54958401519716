import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, ThemeContext } from './ThemeContext';
import Header from './components/Header';
import LandingPage from './LandingPage';
import TeamPage from './TeamPage';
import BlogList from './components/BlogList';
import BlogPage from './BlogPage';
import Footer from './components/Footer';
import TelematicsPage from './TelematicsPage';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <ThemeProvider>
      <ThemeContext.Consumer>
        {({ theme }) => (
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <ScrollToTop />
              <Header />
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/team" element={<TeamPage />} />
                <Route path="/blog" element={<BlogList />} />
                <Route path="/blog/:slug" element={<BlogPage />} />
                <Route path="/telematics" element={<TelematicsPage />} />
              </Routes>
              <Footer />
            </Router>
          </MuiThemeProvider>
        )}
      </ThemeContext.Consumer>
    </ThemeProvider>
  );
}

export default App;

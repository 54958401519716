import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { ThemeContext } from './ThemeContext';
import { FaDatabase, FaCreditCard, FaFileInvoiceDollar, FaShieldAlt } from 'react-icons/fa';
import { TbRoute } from 'react-icons/tb';
import { MdLocalShipping } from 'react-icons/md';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

const FadeInSection = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 }
      }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};

const TelematicsAnimation = () => {
  const { theme } = useContext(ThemeContext);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [visibleLogos, setVisibleLogos] = useState([]);

  // Memoize the integrations array
  const integrations = useMemo(() => [
    'ADA.png', 'Apollo.png', 'Ascend.png', 'Azuga.png', 'BigRoad.png', 'Blue Ink Tech.png', 'BudgetGPS.png',
    'CyntrX.png', 'DriverTech.png', 'ELD Mandate.png', 'EROAD.png', 'EZLOGZ.png', 'Fleet Complete Hub.png',
    'Fleet Complete Web.png', 'FleetHunt.png', 'FleetSharp.png', 'FleetUp.png', 'Forward Thinking.png', 'Geotab.png',
    'GPS Insight.png', 'GPS Trackit.png', 'HCSS.png', 'HOSConnect.png', 'IntelliShift.png', 'JJ Keller.png',
    'Linxup.png', 'Lytx.png', 'Mapon.png', 'Matrack.png', 'Maven.png', 'Motive.png', 'NERO.png', 'Netradyne.png',
    'NexTraq.png', 'OneStepGPS.png', 'Optima.png', 'Orbcomm.png', 'Phoenix.png', 'Platform Science.png', 'Rand McNally.png',
    'Rastrac.png', 'Samsara.png', 'Selective Drive Fleet.png', 'Surfsight.png', 'Swift.png', 'Switchboard.png', 'Telogis.png',
    'Tenna.png', 'Tive.png', 'Traccar.png', 'TrackEnsure.png', 'truck.png', 'Truckspy.png', 'TruckX.png', 'TT.png',
    'Verizon Connect.png', 'VisTracks.png', 'Webfleet.png', 'Zonar.png', 'Zubie.png'
  ], []);

  const getLogoFilter = () => {
    return theme.palette.mode === 'dark'
      ? 'brightness(0) invert(1)'
      : 'none';
  };

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const updateVisibleLogos = () => {
      const shuffled = [...integrations].sort(() => 0.5 - Math.random());
      const newVisibleLogos = shuffled.slice(0, 20).map(logo => ({
        logo,
        key: Math.random(),
      }));
      setVisibleLogos(newVisibleLogos);
    };

    updateVisibleLogos();
    const interval = setInterval(() => {
      const indexToUpdate = Math.floor(Math.random() * 20);
      setVisibleLogos(prev => {
        const newLogos = [...prev];
        const availableLogos = integrations.filter(logo => !prev.some(item => item.logo === logo));
        if (availableLogos.length > 0) {
          const newLogo = availableLogos[Math.floor(Math.random() * availableLogos.length)];
          newLogos[indexToUpdate] = {
            logo: newLogo,
            key: Math.random(),
          };
        }
        return newLogos;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [integrations]);

  const radius = Math.min(dimensions.width, dimensions.height) * 0.4;
  const center = { x: dimensions.width / 2, y: dimensions.height / 2 };

  const logoPositions = Array(20).fill(0).map((_, index) => {
    const angle = (index / 20) * 2 * Math.PI;
    return {
      x: center.x + radius * Math.cos(angle),
      y: center.y + radius * Math.sin(angle),
      angle: angle,
    };
  });

  return (
    <div ref={containerRef} className="relative w-full h-[500px]">
      <motion.img
        src="/catena-telematics.png"
        alt="Catena Logo"
        className="absolute z-10"
        style={{
          width: '160px',
          height: 'auto',
          top: `${center.y - 70}px`,
          left: `${center.x - 65}px`,
          transform: 'translate(-50%, -50%)',
          filter: theme.palette.mode === 'dark' ? 'invert(1) grayscale(1)' : 'none'
        }}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      />

      <AnimatePresence>
        {visibleLogos.map((logoInfo, index) => {
          const position = logoPositions[index];
          const expandDirection = {
            x: Math.cos(position.angle) * 20,
            y: Math.sin(position.angle) * 20,
          };

          return (
            <motion.div
              key={logoInfo.key}
              className="absolute"
              style={{
                top: position.y,
                left: position.x,
                transform: 'translate(-50%, -50%)',
              }}
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              <motion.img
                src={`/eld/${logoInfo.logo}`}
                alt={`Integration ${index + 1}`}
                className="object-contain"
                style={{
                  width: '36px',
                  height: '36px',
                  filter: getLogoFilter(),
                }}
                whileHover={{
                  scale: 2.25,
                  x: expandDirection.x,
                  y: expandDirection.y,
                  transition: {
                    type: 'spring',
                    stiffness: 300,
                    damping: 15,
                    mass: 0.5,
                    duration: 0.9,
                  },
                }}
              />
            </motion.div>
          );
        })}
      </AnimatePresence>

      {/* Increase the number of pings */}
      {Array(100).fill(0).map((_, index) => {
        const position = logoPositions[index % logoPositions.length];
        const initialDelay = Math.random() * 5;
        return (
          <motion.div
            key={`ping-${index}`}
            className="absolute w-3 h-3 bg-blue-500 rounded-full"
            style={{
              top: position.y,
              left: position.x,
              zIndex: 5,
            }}
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: [0, 0.7, 0],
              scale: [0, 1, 0],
              x: [0, center.x - position.x],
              y: [0, center.y - position.y],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.5, 1],
              repeat: Infinity,
              repeatDelay: Math.random() * 5 + 3,
              delay: initialDelay,
            }}
          />
        );
      })}
    </div>
  );
};

const TelematicsPage = () => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const steps = [
    { title: "Seamlessly onboard your fleets", description: "Login and onboard with easy to use, client-side interface." },
    { title: "Leverage real-time insights", description: "Customize alerts with callbacks for instant data updates and decision-making." },
    { title: "Ensure reliability and security", description: "Benefit from our fault-tolerant, high-availability infrastructure and behind-the-scenes API monitoring." },
    { title: "Focus on your core product", description: "Let us maintain, manage, and update your integrations with white-glove technical support." }
  ];

  const features = [
    { icon: FaDatabase, title: "Core Benefits", items: ["Universal API for 60+ ELD devices", "Seamless fleet onboarding", "UIs for fleet management", "System of record"] },
    { icon: MdLocalShipping, title: "TMS", items: ["Customizable, real-time fleet visibility", "Proof-of-delivery validation", "Real-time HOS events and availability", "IFTA and other workflow automations"] },
    { icon: FaCreditCard, title: "Fuel Cards", items: ["Real-time fuel-up detection", "Fuel pump fraud detection", "Transaction validation and logging", "Real-time geofencing and webhooks"] },
    { icon: FaFileInvoiceDollar, title: "Factoring", items: ["Proof-of-delivery validation", "Payout automation", "Fraud detection", "Real-time geofencing and webhooks"] },
    { icon: FaShieldAlt, title: "Insurers", items: ["Live safety event recording", "Historical driving records and analytics", "Driver records for premium optimization", "Integrations with dashcams and ELDs"] },
    { icon: TbRoute, title: "Logistics", items: ["Load optimization", "Route optimization", "Proof-of-delivery validation", "Real-time geofencing and webhooks"] },
  ];

  const CodeSnippet = ({ examples, selectedExample, onSelectExample }) => {
    return (
      <div className="rounded-lg overflow-hidden shadow-lg">
        <div className="flex bg-[#2d2d2d]">
          {examples.map((example, index) => (
            <button
              key={index}
              className={`px-4 py-2 font-semibold text-sm ${
                selectedExample === index
                  ? 'bg-gray-800 text-white'
                  : 'text-gray-400 hover:bg-gray-800 hover:text-white'
              }`}
              onClick={() => onSelectExample(index)}
            >
              {example.title}
            </button>
          ))}
        </div>
        <SyntaxHighlighter
          language="json"
          style={tomorrow}
          customStyle={{
            padding: '20px',
            fontSize: '14px',
            lineHeight: '1.5',
            borderRadius: '0 0 0.5rem 0.5rem',
            margin: 0,
            height: '460px', // Set a fixed height
            overflowY: 'auto' // Add vertical scrollbar when content exceeds height
          }}
        >
          {examples[selectedExample].code}
        </SyntaxHighlighter>
      </div>
    );
  };

  const [selectedFeature, setSelectedFeature] = useState(0);
  const [selectedApiExample, setSelectedApiExample] = useState(0);

  const apiExamples = [
    {
      title: "Vehicle Data",
      code: `GET /vehicle/:id

{
  "id": 103,
  "time": "2022-07-27T07:06:25.000Z",
  "tsp_source": "samsara",
  "oem": "Freightliner",
  "model": "Cascadia",
  "model_year": 2018,
  "vin": "3AKJHHDR9JSJV5535",
  "location": {
    "latitude": 122.142,
    "longitude": -93.343,
    "address": "16 Oak St, Pasadena, CA 91103"
  },
  "speed": 48.3,
  "odometer": 140100
}`
    },
    {
      title: "Asset Data",
      code: `GET /asset/:asset_id

{
  "id": "asset_12345",
  "make": "Great Dane",
  "model": "Freedom LT",
  "model_year": 2020,
  "location": {
    "latitude": 34.1478,
    "longitude": -118.1445,
  },
  "odometer": 50000,
  "tire_pressure": {
    "front_left": 100,
    "front_right": 102,
    "rear_left": 98,
    "rear_right": 101
  }
}`
    },
    {
      title: "Fleet Data",
      code: `GET /fleet/:fleet_id

{
  "id": "fleet_67890",
  "carrier_name": "ABC Trucking Co.",
  "office_address": {
    "street": "456 Transport Ave",
    "city": "Los Angeles",
    "state": "CA",
    "zip": "90001"
  },
  "dot_number": "US DOT 1234567",
  "total_vehicles": 50,
  "total_drivers": 60,
  "active_vehicles": 45,
  "inactive_vehicles": 5
}`
    },
    {
      title: "Driver Data",
      code: `GET /driver/:driver_id

{
  "id": "driver_789",
  "name": "John Doe",
  "phone_number": "+1 (555) 123-4567",
  "carrier_info": {
    "carrier_id": "carrier_456",
    "carrier_name": "ABC Trucking Co."
  },
  "status": "On Duty",
  "license": {
    "number": "D1234567",
    "state": "CA"
  },
  "hours_of_service": {
    "drive_time_remaining": 420,
    "on_duty_time_remaining": 600
  }
}`
    }
  ];

  const scrollToContact = () => {
    navigate('/', { state: { scrollToContact: true } });
    setTimeout(() => {
      const contactSection = document.getElementById('contact');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <div className="min-h-screen" style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
      <div className="py-12 px-4" style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold mb-6 text-center">
            Catena Universal Telematics API
          </h2>
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
              <TelematicsAnimation />
            </div>
            <div className="w-full lg:w-1/2 lg:pl-8">
              <p className="text-xl mb-4 leading-relaxed">
                Catena Telematics is a universal API that eliminates data fragmentation, streamlining access to telematics data for fleet operators, logistics providers, and shippers.
              </p>

              <p className="text-xl mb-4 leading-relaxed">
                With over 60 integrations and connectivity to 10+ million commercial vehicles, Catena Telematics unlocks critical insights such as real-time tracking, driver compliance, Hours of Service (HOS), and fuel usage, ensuring frictionless vehicle onboarding and data-driven decision-making.
              </p>
              <button
                onClick={scrollToContact}
                className="font-bold py-3 px-6 rounded-full transition duration-300 text-lg"
                style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.background.default }}
              >
                Request a Demo
              </button>
            </div>
          </div>
        </div>
      </div>
      <main>
        <FadeInSection>
          <section className="w-full py-16" style={{ backgroundColor: theme.palette.background.paper }}>
            <div className="container mx-auto px-4">
              <h2 className="text-4xl font-bold mb-12 text-center">
                Simple and Powerful API
              </h2>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <div className="w-full md:w-1/2">
                  <p className="text-xl mb-4">
                    Our API provides easy access to real-time data across various aspects of fleet operations, enabling you to build powerful applications and integrations.
                  </p>

                  <p className="text-xl mb-4">
                    With only a few API calls, you can retrieve comprehensive information on vehicles, assets, fleets, drivers, and more!
                  </p>
                </div>
                <div className="w-full md:w-1/2 mb-8 md:mb-0 md:pr-8">
                  <CodeSnippet
                    examples={apiExamples}
                    selectedExample={selectedApiExample}
                    onSelectExample={setSelectedApiExample}
                  />
                </div>
              </div>
            </div>
          </section>
        </FadeInSection>

        <div className="container mx-auto px-4 py-8">
          <FadeInSection>
            <section className="mb-16">
              <div className="flex flex-col md:flex-row">
                <div className="md:w-1/4 flex items-center justify-center md:justify-start mb-6 md:mb-0">
                  <h2 className="text-3xl font-bold mb-8 text-center">Use Cases</h2>
                </div>
                <div className="md:w-3/4">
                  <div className="rounded-lg p-6 shadow-lg flex flex-col md:flex-row" style={{ backgroundColor: theme.palette.background.paper }}>
                    <div className="md:w-1/3 md:border-r border-gray-200 dark:border-gray-700 pr-4">
                      {features.map((feature, index) => (
                        <motion.div
                          key={index}
                          className={`flex items-center cursor-pointer p-3 rounded-lg transition-all duration-300 mb-2 ${
                            selectedFeature === index
                              ? 'text-white'
                              : ''
                          }`}
                          style={{
                            backgroundColor: selectedFeature === index ? theme.palette.primary.main : 'transparent',
                            color: selectedFeature === index ? theme.palette.primary.contrastText : theme.palette.text.primary,
                          }}
                          whileHover={{ scale: 1.03 }}
                          whileTap={{ scale: 0.98 }}
                          onClick={() => setSelectedFeature(index)}
                        >
                          <feature.icon className="text-2xl mr-3" />
                          <span className="text-lg">{feature.title}</span>
                        </motion.div>
                      ))}
                    </div>
                    <div className="md:w-2/3 md:pl-6 mt-6 md:mt-0 flex items-center justify-center">
                      <AnimatePresence mode="wait">
                        <motion.div
                          key={selectedFeature}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.3 }}
                          className="text-center"
                        >
                          <h3 className="text-2xl font-semibold mb-4">
                            {features[selectedFeature].title}
                          </h3>
                          <ul className="space-y-2">
                            {features[selectedFeature].items.map((item, itemIndex) => (
                              <li key={itemIndex} className="flex items-center justify-center">
                                <span>{item}</span>
                              </li>
                            ))}
                          </ul>
                        </motion.div>
                      </AnimatePresence>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </FadeInSection>
        </div>

          <FadeInSection>
            <section className="mb-16 py-12 rounded-lg" style={{ backgroundColor: theme.palette.background.paper }}>
              <div className="container mx-auto px-4">
                <h2 className="text-4xl font-extrabold mb-8 text-center">Revolutionize Your Fleet Management</h2>
                <div className="flex flex-col md:flex-row items-start justify-between">
                  <div className="w-full md:w-1/2 p-8">
                    <h3 className="text-3xl font-semibold mb-4">10+ Million Vehicles, One Platform</h3>
                    <p className="text-lg mb-4">Unlock the potential of real-time and historical data to craft tailored solutions for fleet management, FinTech, and insurance.</p>
                    <ul className="list-none space-y-2">
                      <li className="flex items-center">
                        <span className="text-blue-500 mr-2">✓</span> Real-time location tracking
                      </li>
                      <li className="flex items-center">
                        <span className="text-blue-500 mr-2">✓</span> Route reconstruction
                      </li>
                      <li className="flex items-center">
                        <span className="text-blue-500 mr-2">✓</span> Automated proof-of-delivery
                      </li>
                      <li className="flex items-center">
                        <span className="text-blue-500 mr-2">✓</span> Speed and mileage data
                      </li>
                      <li className="flex items-center">
                        <span className="text-blue-500 mr-2">✓</span> Fuel level and transaction data
                      </li>
                    </ul>
                    <button
                      onClick={scrollToContact}
                      className="mt-6 px-6 py-3 rounded-full shadow-lg transition duration-300"
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText
                      }}
                    >
                      Request a Demo
                    </button>
                  </div>
                  <div className="w-full md:w-1/2 p-8">
                    <h3 className="text-3xl font-semibold mb-4">Data Driven Insights</h3>
                    <p className="text-lg mb-4">
                      Harness the power of our comprehensive telematics data to drive informed decision-making and optimize your operations.
                    </p>
                    <ul className="list-none space-y-2">
                      <li className="flex items-center">
                        <span className="text-blue-500 mr-2">✓</span> Predictive maintenance insights
                      </li>
                      <li className="flex items-center">
                        <span className="text-blue-500 mr-2">✓</span> Driver behavior analysis
                      </li>
                      <li className="flex items-center">
                        <span className="text-blue-500 mr-2">✓</span> Fuel efficiency optimization
                      </li>
                      <li className="flex items-center">
                        <span className="text-blue-500 mr-2">✓</span> Custom reporting and dashboards
                      </li>
                      <li className="flex items-center">
                        <span className="text-blue-500 mr-2">✓</span> Integration with existing systems
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </FadeInSection>

        <div className="container mx-auto px-4 py-8">
          <FadeInSection>
            <section className="mb-16">
              <h2 className="text-4xl font-extrabold mb-8 text-center" style={{ color: theme.palette.text.primary }}>Catena Telematics Experience</h2>
              <div className="relative">
                <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1" style={{ backgroundColor: theme.palette.primary.main }}></div>
                {steps.map((step, index) => (
                  <div key={index} className={`flex ${index % 2 === 0 ? 'flex-row-reverse' : 'flex-row'} items-center mb-8`}>
                    <div className="w-1/2"></div>
                    <div className="w-1/2 p-4">
                      <div className="p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center" style={{
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary
                      }}>
                        <h3 className="text-xl font-semibold mb-3">{step.title}</h3>
                        <p style={{ color: theme.palette.text.secondary }}>{step.description}</p>
                      </div>
                    </div>
                    <div className="absolute left-1/2 transform -translate-x-1/2 w-8 h-8 rounded-full flex items-center justify-center" style={{ backgroundColor: theme.palette.primary.main }}>
                      <span className="text-white font-bold"></span>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </FadeInSection>
        </div>
      </main>
    </div>
  );
};

export default TelematicsPage;

import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Typography, Box, Container, Card, CardContent, CardMedia, Grid, Chip, Button } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import blogPosts from '../blog/blogPosts.json';

const BlogList = () => {
  const theme = useTheme();

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Parse dates and sort blog posts by date in descending order
  const sortedBlogPosts = [...blogPosts].sort((a, b) => {
    // Handle different date formats (MM/DD/YYYY or YYYY-MM-DD)
    const dateA = a.date.includes('/') ?
      new Date(a.date) :
      new Date(a.date.replace(/-/g, '/'));
    const dateB = b.date.includes('/') ?
      new Date(b.date) :
      new Date(b.date.replace(/-/g, '/'));
    return dateB - dateA;
  });

  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 12 }}>
      <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 700, color: theme.palette.text.primary, mb: 6, textAlign: 'center' }}>
        Latest Updates
      </Typography>
      <Grid container spacing={4}>
        {sortedBlogPosts.map((post) => (
          <Grid item xs={12} sm={6} md={4} key={post.slug || post.url}>
            <Card sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': { transform: 'translateY(-5px)', boxShadow: 6 }
            }}>
              <Box sx={{ position: 'relative', paddingTop: '56.25%' /* 16:9 Aspect Ratio */ }}>
                <CardMedia
                  component="img"
                  image={post.image ? `/blog/images/${post.image}` : '/blog/images/udc_blue.png'}
                  alt={post.title}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
              </Box>
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 600, color: theme.palette.secondary.main, mb: 2 }}>
                    {post.title}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <AccessTimeIcon fontSize="small" sx={{ mr: 1, color: theme.palette.text.secondary }} />
                    <Typography variant="body2" color="text.secondary">
                      {formatDate(post.date)}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {post.excerpt}
                  </Typography>
                </Box>
                <Box>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                    {post.tags && post.tags.split(',').map((tag, index) => (
                      <Chip key={index} label={tag.trim()} size="small" />
                    ))}
                  </Box>
                  <Button
                    component={post.url ? 'a' : Link}
                    to={post.url ? undefined : `/blog/${post.slug}`}
                    href={post.url}
                    target={post.url ? "_blank" : undefined}
                    rel={post.url ? "noopener noreferrer" : undefined}
                    endIcon={<ArrowForwardIcon />}
                    sx={{ alignSelf: 'flex-start', fontWeight: 500 }}
                  >
                    {post.url ? 'Listen Now' : 'Read More'}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BlogList;

import React, { useState, useContext } from 'react';
import { IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import CampaignIcon from '@mui/icons-material/Campaign';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../ThemeContext';

const Header = () => {
  const { theme, colorMode } = useContext(ThemeContext);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(true); // set to true to show banner

  const menuItems = [
    { text: 'Home', path: '/' },
    { text: 'Telematics', path: '/telematics' },
    { text: 'Team', path: '/team' },
    { text: 'Blog', path: '/blog' },
    { text: 'Login', path: 'https://portal.catenaclearing.io', external: true },
  ];

  return (
    <>
      {showBanner && (
        <div
          className="relative py-3 px-4 text-white"
          style={{
            background: theme.palette.mode === 'dark'
              ? `linear-gradient(90deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`
              : `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`
          }}
        >
          <div className="container mx-auto flex items-center justify-between">
            <div className="flex items-center">
              <CampaignIcon className="mr-2 text-2xl animate-pulse" />
              <span className="font-medium text-lg">Introducing Catena Telematics: Revolutionizing Fleet Management</span>
            </div>
            <Link
              to="/blog/introducing-telematics"
              className="flex items-center text-white hover:text-blue-200 transition duration-300"
            >
              <span className="mr-1 font-semibold">Read the announcement</span>
              <ArrowForwardIcon fontSize="small" />
            </Link>
            <button
              onClick={() => setShowBanner(false)}
              className="absolute top-1 right-1 text-white hover:text-blue-200 transition duration-300"
              aria-label="Close banner"
            >
              <CloseIcon fontSize="small" />
            </button>
          </div>
        </div>
      )}
      <header className="bg-opacity-90 shadow-md sticky top-0 z-50" style={{ backgroundColor: theme.palette.background.paper }}>
        <nav className="container mx-auto px-4 sm:px-6 py-0 flex justify-between items-center">
          <div className="h-24">
            <Link to="/">
              <img
                src={theme.palette.mode === 'dark' ? "logo-rectangular-dark-mono.png" : "/logo-rectangular.png"}
                alt="Catena Clearing"
                className="h-full"
              />
            </Link>
          </div>
          <div className="hidden md:flex space-x-6 ml-auto items-center">
            {menuItems.map((item) => (
              item.external ? (
                <a
                  key={item.text}
                  href={item.path}
                  className="text-lg hover:text-blue-600 transition duration-300"
                  style={{ color: theme.palette.text.secondary }}
                >
                  {item.text}
                </a>
              ) : (
                <Link
                  key={item.text}
                  to={item.path}
                  className="text-lg hover:text-blue-600 transition duration-300"
                  style={{ color: theme.palette.text.secondary }}
                >
                  {item.text}
                </Link>
              )
            ))}
            <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
              {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </div>
          <div className="md:hidden flex items-center">
            <IconButton onClick={colorMode.toggleColorMode} color="inherit">
              {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            <IconButton onClick={() => setMobileMenuOpen(!mobileMenuOpen)} color="inherit">
              {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </div>
        </nav>
        {mobileMenuOpen && (
          <div className="md:hidden" style={{ backgroundColor: theme.palette.background.paper }}>
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {menuItems.map((item) => (
                item.external ? (
                  <a
                    key={item.text}
                    href={item.path}
                    className="block px-3 py-2 rounded-md text-base font-medium hover:text-blue-600 transition duration-300"
                    style={{ color: theme.palette.text.secondary }}
                  >
                    {item.text}
                  </a>
                ) : (
                  <Link
                    key={item.text}
                    to={item.path}
                    className="block px-3 py-2 rounded-md text-base font-medium hover:text-blue-600 transition duration-300"
                    style={{ color: theme.palette.text.secondary }}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.text}
                  </Link>
                )
              ))}
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
